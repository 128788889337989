const interactiveStatisticsCharts=[
    {
        id: 1,
        title:"Prevalence of Parental Alcohol or Drug Abuse as a Condition Associated with Removal",
        subTitle: "In the United States, 2000 to 2021",
        description: "The percentage of children in out-of-home care with parental alcohol or other drug (AOD) abuse as a condition associated with removal has steadily increased from 18.5% in 2000 to 39.1% in 2021.", 
        slug: "1-2-prevalence-aod-removal", 
        author: "AFCARS Data", 
        year: "2000-2021, as of 3/21/23",
        metaDescription: "The percentage of children in out-of-home care with parental alcohol or other drug (AOD) abuse as a condition associated with removal has steadily increased from 18.5% in 2000 to 39.1% in 2021.",
        fileName: "statistics-1-prevalence-aod-removal.pdf",
        fileSize: "95KB"
    },
    {
        id: 2,
        title:"Number of Children who Entered Out of Home Care, by Age at Removal in the United States, 2021", 
        description: "Children from birth to age 5 make up nearly half (49.5%, N=102,466) of the children placed in out-of-home care during Fiscal Year 2021. Children age 6 — 12 make up 27.8% (N=57,560) and children age 13 — 18+ make up 22.6% (N=46,823) of the children removed from their homes and placed in out-of-home care during the same time period.",
        slug: "6-enter-out-of-home-care-age-at-removal",
        author: "AFCARS Data", 
        year: "2021, as of 3/21/23",
        metaDescription: "Children from birth to age 5 make up nearly half (49.5%, N=102,466) of the children placed in out-of-home care during Fiscal Year 2021. Children age 6 — 12 make up 27.8% (N=57,560) and children age 13 — 18+ make up 22.6% (N=46,823) of the children removed from their homes and placed in out-of-home care during the same time period.",
        fileName: "statistics-6-enter-out-of-home-care-age-at-removal.pdf",
        fileSize: "93KB"
    },
    {
        id: 3,
        title:"Number of Children who Entered Out of Home Care with Parental Alcohol or Drug Abuse as a Condition Associated with Removal, by Age in the United States, 2021", 
        description: "Children from birth to age 5 make up more than half (61.7%, N=49,888) of the children placed in out-of-home care with parental AOD as a condition associated with removal during Fiscal Year 2021. Children age 6 — 12 make up 27.5% (N=22,233) and children age 13 — 18+ make up 10.8% (N=8,756) of the children removed from their homes and placed in out-of-home care during the same time period.",
        slug: "7-enter-out-of-home-care-age-at-removal-aod", 
        author: "AFCARS Data", 
        year: "2021, as of 3/21/23",
        metaDescription: "Children from birth to age 5 make up more than half (61.7%, N=49,888) of the children placed in out-of-home care with parental AOD as a condition associated with removal during Fiscal Year 2021. Children age 6 — 12 make up 27.5% (N=22,233) and children age 13 — 18+ make up 10.8% (N=8,756) of the children removed from their homes and placed in out-of-home care during the same time period.",
        fileName: "statistics-7-enter-out-of-home-care-age-at-removal-aod.pdf",
        fileSize: "125KB"
    },
    {
        id: 4,
        title:"Prevalence of Parental Alcohol or Drug Abuse as a Condition Associated with Removal",
        subTitle: "By State, 2021",
        description: "Nationally, 39.1% of children placed in out-of-home care had parental alcohol or other drug (AOD) abuse as a condition associated with removal in 2021. The map provides the percent by state. States often anecdotally report that the percentage of child welfare removals involving parental AOD abuse is much higher in their state than indicated in the data. Possible explanations for these discrepancies may include 1) lack of child welfare protocols for screening and assessment regarding identification of substance use disorders; 2) inconsistent protocols regarding data entry for child welfare; 3) discrepancies in how AOD abuse is captured in the state child welfare’s data systems; and 4) differences in the point at which the AOD abuse is identified and entered in the data system. Often, at the local level, multiple removal reasons are reported and sometimes only the primary reason for removal is reported by the federal system(s).", 
        slug: "1-2-prevalence-aod-removal",
        author: "AFCARS Data", 
        year: "2021, as of 3/21/23",
        metaDescription: "Nationally, 39.1% of children placed in out-of-home care had parental alcohol or other drug (AOD) abuse as a condition associated with removal in 2021. The map provides the percent by state. States often anecdotally report that the percentage of child welfare removals involving parental AOD abuse is much higher in their state than indicated in the data. Possible explanations for these discrepancies may include 1) lack of child welfare protocols for screening and assessment regarding identification of substance use disorders; 2) inconsistent protocols regarding data entry for child welfare; 3) discrepancies in how AOD abuse is captured in the state child welfare’s data systems; and 4) differences in the point at which the AOD abuse is identified and entered in the data system. Often, at the local level, multiple removal reasons are reported and sometimes only the primary reason for removal is reported by the federal system(s).",
        fileName: "statistics-2-parental-aod-removal-by-state.pdf",
        fileSize: "104KB"
    },
    {
        id: 5,
        title:"Percent of Children who Entered Out of Home Care who were Under Age 1 in the United States, 2000 to 2021", 
        description: "The percentage of children under age 1 who entered out-of-home care increased from 13.4% in 2000 to 20.5% in 2021.", 
        slug: "4-enter-out-of-home-care-age-under-1",
        author: "AFCARS Data", 
        year: "2000-2021, as of 3/21/23",
        metaDescription: "The percentage of children under age 1 who entered out-of-home care increased from 13.4% in 2000 to 20.5% in 2021.",
        fileName: "statistics-4-enter-out-of-home-care-age-under-1.pdf",
        fileSize: "86KB"
    },
    {
        id: 6,
        title:"Percent of Children with Parental Alcohol or Drug Abuse as a Condition Associated with Removal who were Under Age 1 in the United States, 2000 to 2021", 
        description: "The percentage of children under age 1 who entered out-of-home care with parental alcohol or drug abuse as a condition associated with removal increased from 27.8% in 2000 to 53.5% in 2021.", 
        slug: "5-removal-age-under-1",
        author: "AFCARS Data", 
        year: "2000-2021, as of 3/21/23",
        metaDescription: "The percentage of children under age 1 who entered out-of-home care with parental alcohol or drug abuse as a condition associated with removal increased from 27.8% in 2000 to 53.5% in 2021.",
        fileName: "statistics-5-removal-age-under-1.pdf",
        fileSize: "95KB"
    },
    {
        id: 7,
        title:"Substance Abuse Treatment Admissions by Substance Use Flag and Gender in the United States, 2020*", 
        description: "Alcohol was the most frequently identified substance reported at treatment admission for both men and women, followed by marijuana and heroin. While this data summarizes the national trends for treatment admission, state specific patterns of use differ widely. Collaboratives and stakeholders are encouraged to look at their state specific data trends.", 
        slug: "8-treatment-admissions-by-gender",
        author: "TEDS-A Data", 
        year: "2020",
        metaDescription: "Alcohol was the most frequently identified substance reported at treatment admission for both men and women, followed by marijuana and heroin. While this data summarizes the national trends for treatment admission, state specific patterns of use differ widely. Collaboratives and stakeholders are encouraged to look at their state specific data trends.",
        fileName: "statistics-8-treatment-admissions-by-gender.pdf",
        fileSize: "108KB"
    },
    {
        id: 8,
        title:"Treatment Admissions for Women of Childbearing Age (Ages 15-44) Who Were Pregnant at Entry in the United States, 2020**", 
        description: "Nationally, 3.9% (N=14,073) of treatment admissions were for women who were pregnant, this percentage varies greatly by state.", 
        slug: "9-treatment-admissions-pregnant",
        author: "TEDS-A Data", 
        year: "2020",
        metaDescription: "Nationally, 3.9% (N=14,073) of treatment admissions were for women who were pregnant, this percentage varies greatly by state.",
        fileName: "statistics-9-treatment-admissions-pregnant.pdf",
        fileSize: "89KB"
    },
    {
        id: 9,
        title: "Parental Alcohol or Drug Abuse as a Condition Associated with Removal for Children by Age, 2021",
        subTitle: "Age 1 and Older",
        description: "Slightly more than half of the children under age 1 (51.3%) removed from their homes and placed in out-of-home care had parental AOD abuse a condition associated with removal. The percentage for children age 1 and older (36.0%) was closer to the overall national average (39.0%).",
        slug: "3-child-removal-by-age-parental-aod",
        author: "AFCARS Data",
        year: "2021, as of 3/21/23",
        metaDescription: "Slightly more than half of the children under age 1 (51.3%) removed from their homes and placed in out-of-home care had parental AOD abuse a condition associated with removal. The percentage for children age 1 and older (36.0%) was closer to the overall national average (39.0%).",
        fileName: "statistics-3-child-removal-by-age-parental-aod.pdf",
        fileSize: "102KB"
    },
    {
        id: 10,
        title: "Parental Alcohol or Drug Abuse as a Condition Associated with Removal for Children by Age, 2021",
        subTitle: "Under Age 1",
        description: "Slightly more than half of the children under age 1 (51.3%) removed from their homes and placed in out-of-home care had parental AOD abuse a condition associated with removal. The percentage for children age 1 and older (36.0%) was closer to the overall national average (39.0%).",
        slug: "3-child-removal-by-age-parental-aod",
        author: "AFCARS Data",
        year: "2021, as of 3/21/23",
        metaDescription: "Slightly more than half of the children under age 1 (51.3%) removed from their homes and placed in out-of-home care had parental AOD abuse a condition associated with removal. The percentage for children age 1 and older (36.0%) was closer to the overall national average (39.0%).",
        fileName: "statistics-3-child-removal-by-age-parental-aod.pdf",
        fileSize: "102KB"
    }
];
export default interactiveStatisticsCharts;
