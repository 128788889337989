const StatisticsData3UnderAge1 = [
    { 
        id: 1,
        xAxis: "AK", 
        name: "Alaska", 
        yAxis: "75.0", 
        number: "565"
    },
    { 
        id: 2,
        xAxis: "ME", 
        name: "Maine",
        yAxis: "50.7",
        number: "368"
    },
    { 
        id: 3,
        xAxis: "VT", 
        name: "Vermont",
        yAxis: "46.9",
        number: "123"
    },
    { 
        id: 4,
        xAxis: "NH", 
        name: "New Hampshire",
        yAxis: "26.9",
        number: "72"
    },
    { 
        id: 5,
        xAxis: "WA", 
        name: "Washington",
        yAxis: "66.2",
        number: "2324"
    },
    { 
        id: 6,
        xAxis: "MT", 
        name: "Montana",
        yAxis: "48.6",
        number: "476"
    },
    { 
        id: 7,
        xAxis: "ND", 
        name: "North Dakota",
        yAxis: "67.1",
        number: "312"
    },
    { 
        id: 8, 
        xAxis: "MN", 
        name: "Minnesota",
        yAxis: "65.1",
        number: "1372"
    },
    { 
        id: 9,
        xAxis: "WI", 
        name: "Wisconsin",
        yAxis: "49.1",
        number: "1066"
    },
    { 
        id: 10,
        xAxis: "MI", 
        name: "Michigan",
        yAxis: "53.1",
        number: "1657"
    },
    { 
        id: 11,
        xAxis: "NY", 
        name: "New York",
        yAxis: "39.2",
        number: "1844"
    },
    { 
        id: 12,
        xAxis: "MA", 
        name: "Massachusetts",
        yAxis: "48.5",
        number: "1243"
    },
    { 
        id: 13,
        xAxis: "RI", 
        name: "Rhode Island",
        yAxis: "51.6",
        number: "320"
    },
    { 
        id: 14,
        xAxis: "ID", 
        name: "Idaho",
        yAxis: "63.1",
        number: "320"
    },
    { 
        id: 15,
        xAxis: "WY", 
        name: "Wyoming",
        yAxis: "70.5",
        number: "146"
    },
    { 
        id: 16,
        xAxis: "SD", 
        name: "South Dakota",
        yAxis: "71.8",
        number: "328"
    },
    { 
        id: 17,
        xAxis: "IA", 
        name: "Iowa",
        yAxis: "70.8",
        number: "908"
    },
    { 
        id: 18,
        xAxis: "IL", 
        name: "Illinois",
        yAxis: "14.0",
        number: "907"
    },
    { 
        id: 19,
        xAxis: "IN", 
        name: "Indiana",
        yAxis: "72.5",
        number: "3180"
    },
    { 
        id: 20,
        xAxis: "OH", 
        name: "Ohio",
        yAxis: "47.2",
        number: "2256"
    },
    { 
        id: 21,
        xAxis: "PA", 
        name: "Pennsylvania",
        yAxis: "53.4",
        number: "2284"
    },
    { 
        id: 22,
        xAxis: "NJ", 
        name: "New Jersey",
        yAxis: "56.1",
        number: "840"
    },
    { 
        id: 23,
        xAxis: "CT", 
        name: "Connecticut",
        yAxis: "54.0",
        number: "655"
    },
    { 
        id: 24,
        xAxis: "OR", 
        name: "Oregon",
        yAxis: "67.7",
        number: "1118"
    },
    { 
        id: 25,
        xAxis: "NV", 
        name: "Nevada",
        yAxis: "20.1",
        number: "332"
    },
    { 
        id: 26,
        xAxis: "CO", 
        name: "Colorado",
        yAxis: "76.3",
        number: "1247"
    },
    { 
        id: 27,
        xAxis: "NE", 
        name: "Nebraska",
        yAxis: "52.1",
        number: "440"
    },
    { 
        id: 28,
        xAxis: "MO", 
        name: "Missouri",
        yAxis: "70.5",
        number: "2654"
    },
    { 
        id: 29,
        xAxis: "KY", 
        name: "Kentucky",
        yAxis: "61.6",
        number: "1416"
    },
    { 
        id: 30,
        xAxis: "WV", 
        name: "West Virgina",
        yAxis: "75.9",
        number: "1611"
    },
    { 
        id: 31,
        xAxis: "VA", 
        name: "Virginia",
        yAxis: "55.3",
        number: "591"
    },
    { 
        id: 32, 
        xAxis: "MD", 
        name: "Maryland",
        yAxis: "51.9",
        number: "558"
    },
    { 
        id: 33,
        xAxis: "DE",
        name: "Delaware",
        yAxis: "37.2",
        number: "55"
    },
    { 
        id: 34,
        xAxis: "CA", 
        name: "California",
        yAxis: "16.7",
        number: "2612"
    },
    { 
        id: 35,
        xAxis: "UT", 
        name: "Utah",
        yAxis: "79.4",
        number: "540"
    },
    { 
        id: 36,
        xAxis: "NM", 
        name: "New Mexico",
        yAxis: "56.5",
        number: "316"
    },
    { 
        id: 37,
        xAxis: "KS", 
        name: "Kansas",
        yAxis: "48.6",
        number: "731"
    },
    { 
        id: 38,
        xAxis: "AR", 
        name: "Arkansas",
        yAxis: "64.9",
        number: "855"
    },
    { 
        id: 39,
        xAxis: "TN", 
        name: "Tennessee",
        yAxis: "70.0",
        number: "1521"
    },
    { 
        id: 40,
        xAxis: "NC", 
        name: "North Carolina",
        yAxis: "55.7",
        number: "1597"
    },
    { 
        id: 41,
        xAxis: "SC", 
        name: "South Carolina",
        yAxis: "30.2",
        number: "300"
    },
    { 
        id: 42,
        xAxis: "DC", 
        name: "District of Columbia",
        yAxis: "27.0",
        number: "38"
    },
    { 
        id: 43,
        xAxis: "AZ", 
        name: "Arizona",
        yAxis: "47.8",
        number: "2166"
    },
    { 
        id: 44,
        xAxis: "OK", 
        name: "Oklahoma",
        yAxis: "65.3",
        number: "2015"
    },
    { 
        id: 45,
        xAxis: "LA", 
        name: "Louisiana",
        yAxis: "2.0",
        number: "28"
    },
    { 
        id: 46,
        xAxis: "MS", 
        name: "Mississippi",
        yAxis: "58.4",
        number: "687"
    },
    { 
        id: 47,
        xAxis: "AL", 
        name: "Alabama",
        yAxis: "60.8",
        number: "1108"
    },
    { 
        id: 48,
        xAxis: "GA", 
        name: "Georgia",
        yAxis: "61.1",
        number: "1969"
    },
    { 
        id: 49,
        xAxis: "HI", 
        name: "Hawaii",
        yAxis: "48.3",
        number: "232"
    },
    { 
        id: 50,
        xAxis: "TX", 
        name: "Texas",
        yAxis: "76.7",
        number: "7564"
    },
    { 
        id: 51,
        xAxis: "FL", 
        name: "Florida",
        yAxis: "66.0",
        number: "5152"
    },
    { 
        id: 52,
        xAxis: "PR", 
        name: "Puerto Rico",
        yAxis: "31.1",
        number: "111"
    },
    ];
    export default StatisticsData3UnderAge1;