import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import {Breadcrumb, Button, StatisticsHexagonMap} from "components/ui";
import usStateRPGMatrix from "data/rpg-map-colors.js";
import StatisticsMapLegend from 'assets/images/statistics-map-legend.inline.svg';
import interactiveStatisticsCharts from 'data/interactive-statistics-series-data.js';
import StatisticsData3UnderAge1 from "data/data-3-child-removal-by-age-parental-aod-under-age-1.js"
import StatisticsData3Age1Older from "data/data-3-child-removal-by-age-parental-aod-age-1-older.js"

const isBrowser = typeof window !== "undefined"

const StatisticsPage3=({location})=>{
        let thisLocationPathname = ''
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
        }
        const pageData = interactiveStatisticsCharts.filter(data=>data.id === 9 || data.id === 10)
        const statisticsPageData1 = pageData[0];
        const statisticsPageData2 = pageData[1];
        const seo = {
            metaTitle: statisticsPageData1.title,
            metaDescription: statisticsPageData1.metaDescription
        }
    return (
        <Layout location={location}>
            <SeoComponent title={seo.metaTitle} description={seo.metaDescription} />
            <div className="grid-container padding-bottom-0">
                <Breadcrumb pathname={thisLocationPathname} customCurrentPage={seo.metaTitle} />
                <h1 name="main-content" id="main-content">{seo.metaTitle}</h1>
                <div className="padding-bottom-5">
                    <h2>{statisticsPageData1.subTitle}</h2>
                    <p><strong>Source:</strong> {statisticsPageData1.author}, {statisticsPageData1.year}</p>
                    <div className="display-flex">
                        <p className="text-bold">National Average 36.0%</p>
                        <p className="text-bold margin-left-5">N=480,645</p>
                    </div>
                    <StatisticsHexagonMap 
                     usStateMatrix={usStateRPGMatrix}
                     usStateData={StatisticsData3Age1Older}
                     mapID="parental-alcohol-or-drug-abuse-as-an-identified-condition-of-removal-for-children-age-1-and-older-hexagon-map"
                     mapIDValue={0}
                     />
                    <div className="desktop:display-block tablet:display-none mobile:display-none">
                        <StatisticsMapLegend />
                    </div>
                    <Button color="primary-button" type="button" size="padding-x-5" btnType="usa-button statistics-btn" link={`/files/${statisticsPageData1.fileName}`} labelText={`Learn more About ${seo.metaTitle} - Age 1 and Older`}>
                        View Source Data (PDF {statisticsPageData1.fileSize})
                    </Button>
                    <p>Section: Policy and Practice Resources</p>
                </div>
                <div className="padding-bottom-5">
                    <h2>{statisticsPageData2.subTitle}</h2>
                    <p><strong>Source:</strong> {statisticsPageData2.author}, {statisticsPageData2.year}</p>
                    <div className="display-flex">
                        <p className="text-bold">National Average 51.3%</p>
                        <p className="text-bold margin-left-5">N=123,178</p>
                    </div>
                    <StatisticsHexagonMap 
                     usStateMatrix={usStateRPGMatrix}
                     usStateData={StatisticsData3UnderAge1}
                     mapID="parental-alcohol-or-drug-abuse-as-an-identified-condition-of-removal-for-children-under-age-1-hexagon-map"
                     mapIDValue={1}
                    />
                    <div className="desktop:display-block tablet:display-none mobile:display-none">
                        <StatisticsMapLegend />
                    </div>
                    <p>
                        Slightly more than half of the children under age 1 (51.3%) removed from their homes and placed in out-of-home care had parental AOD abuse a condition associated with removal. 
                        The percentage for children age 1 and older (36.0%) was closer to the overall national average (39.0%).
                    </p>
                    <p><strong>Note:</strong> Estimates based on <strong className="text-underline">all children in out-of-home care at some point</strong> during the Fiscal Year</p>
                    <Button color="primary-button" type="button" size="padding-x-5" btnType="usa-button statistics-btn" link={`/files/${statisticsPageData2.fileName}`} labelText={`Learn more About ${seo.metaTitle} - Under Age 1`}>
                        View Source Data (PDF {statisticsPageData2.fileSize})
                    </Button>
                    <p>Section: Policy and Practice Resources</p>
                </div>
            </div>       
        </Layout>
    )
}
export default StatisticsPage3;