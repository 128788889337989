// 0 == blank
  // 1 == rgb(204, 218, 227) == light blue
  // 2 == rgb(200, 197, 217) == light navy blue
  // 3 == rgb(235, 223, 231) == light pink
  // 4 == rgb(51, 106, 144) == blue
  // 5 == rgb(34, 25, 102) == navy blue
  // 6 == rgb(115, 25, 83) == dark-pink
  // 7 == rgb(82, 82, 82) == dark gray
  const usStateRPGMatrix = [
    [6,0,0,0,0,0,0,0,0,0,0,6],
    [0,0,0,0,0,0,0,0,0,6,6,0],
    [0,7,6,7,6,6,0,7,0,5,6,6],
    [0,6,7,6,5,5,6,5,6,6,7,0],
    [0,5,6,5,6,5,5,6,6,7,6,0],
    [0,4,7,6,6,7,5,6,7,7,0,0],
    [0,0,0,6,5,7,7,6,5,0,0,0],
    [7,0,0,0,6,0,0,5,0,0,0,0],
    [0,0,0,0,0,0,0,0,0,0,7,0]
  ];
  export default usStateRPGMatrix;
