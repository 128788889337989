const StatisticsData3Age1Older =  
 [
    { 
        id: 1,
        xAxis: "AK", 
        name: "Alaska", 
        yAxis: "65.4", 
        number: "2203"
    },
    { 
        id: 2,
        xAxis: "ME", 
        name: "Maine",
        yAxis: "51.7",
        number: "1270"
    },
    { 
        id: 3,
        xAxis: "VT", 
        name: "Vermont",
        yAxis: "28.6",
        number: "389"
    },
    { 
        id: 4,
        xAxis: "NH", 
        name: "New Hampshire",
        yAxis: "13.2",
        number: "188"
    },
    { 
        id: 5,
        xAxis: "WA", 
        name: "Washington",
        yAxis: "36.8",
        number: "3620"
    },
    { 
        id: 6,
        xAxis: "MT", 
        name: "Montana",
        yAxis: "37.2",
        number: "1508"
    },
    { 
        id: 7,
        xAxis: "ND", 
        name: "North Dakota",
        yAxis: "37.0",
        number: "741"
    },
    { 
        id: 8, 
        xAxis: "MN", 
        name: "Minnesota",
        yAxis: "48.3",
        number: "4815"
    },
    { 
        id: 9,
        xAxis: "WI", 
        name: "Wisconsin",
        yAxis: "35.4",
        number: "2983"
    },
    { 
        id: 10,
        xAxis: "MI", 
        name: "Michigan",
        yAxis: "35.5",
        number: "4036"
    },
    { 
        id: 11,
        xAxis: "NY", 
        name: "New York",
        yAxis: "28.3",
        number: "4337"
    },
    { 
        id: 12,
        xAxis: "MA", 
        name: "Massachusetts",
        yAxis: "31.2",
        number: "3442"
    },
    { 
        id: 13,
        xAxis: "RI", 
        name: "Rhode Island",
        yAxis: "35.1",
        number: "793"
    },
    { 
        id: 14,
        xAxis: "ID", 
        name: "Idaho",
        yAxis: "37.0",
        number: "810"
    },
    { 
        id: 15,
        xAxis: "WY", 
        name: "Wyoming",
        yAxis: "47.8",
        number: "667"
    },
    { 
        id: 16,
        xAxis: "SD", 
        name: "South Dakota",
        yAxis: "55.0",
        number: "1194"
    },
    { 
        id: 17,
        xAxis: "IA", 
        name: "Iowa",
        yAxis: "57.7",
        number: "3540"
    },
    { 
        id: 18,
        xAxis: "IL", 
        name: "Illinois",
        yAxis: "10.2",
        number: "2126"
    },
    { 
        id: 19,
        xAxis: "IN", 
        name: "Indiana",
        yAxis: "59.0",
        number: "10324"
    },
    { 
        id: 20,
        xAxis: "OH", 
        name: "Ohio",
        yAxis: "27.2",
        number: "5718"
    },
    { 
        id: 21,
        xAxis: "PA", 
        name: "Pennsylvania",
        yAxis: "32.7",
        number: "5612"
    },
    { 
        id: 22,
        xAxis: "NJ", 
        name: "New Jersey",
        yAxis: "38.6",
        number: "1463"
    },
    { 
        id: 23,
        xAxis: "CT", 
        name: "Connecticut",
        yAxis: "40.1",
        number: "1534"
    },
    { 
        id: 24,
        xAxis: "OR", 
        name: "Oregon",
        yAxis: "54.7",
        number: "3629"
    },
    { 
        id: 25,
        xAxis: "NV", 
        name: "Nevada",
        yAxis: "14.6",
        number: "801"
    },
    { 
        id: 26,
        xAxis: "CO", 
        name: "Colorado",
        yAxis: "43.2",
        number: "2816"
    },
    { 
        id: 27,
        xAxis: "NE", 
        name: "Nebraska",
        yAxis: "33.5",
        number: "1563"
    },
    { 
        id: 28,
        xAxis: "MO", 
        name: "Missouri",
        yAxis: "46.7",
        number: "7270"
    },
    { 
        id: 29,
        xAxis: "KY", 
        name: "Kentucky",
        yAxis: "37.6",
        number: "4262"
    },
    { 
        id: 30,
        xAxis: "WV", 
        name: "West Virgina",
        yAxis: "50.7",
        number: "4963"
    },
    { 
        id: 31,
        xAxis: "VA", 
        name: "Virginia",
        yAxis: "30.3",
        number: "1971"
    },
    { 
        id: 32, 
        xAxis: "MD", 
        name: "Maryland",
        yAxis: "26.2",
        number: "1157"
    },
    { 
        id: 33,
        xAxis: "DE",
        name: "Delaware",
        yAxis: "15.1",
        number: "87"
    },
    { 
        id: 34,
        xAxis: "CA", 
        name: "California",
        yAxis: "10.3",
        number: "5767"
    },
    { 
        id: 35,
        xAxis: "UT", 
        name: "Utah",
        yAxis: "57.5",
        number: "1834"
    },
    { 
        id: 36,
        xAxis: "NM", 
        name: "New Mexico",
        yAxis: "40.6",
        number: "1003"
    },
    { 
        id: 37,
        xAxis: "KS", 
        name: "Kansas",
        yAxis: "33.3",
        number: "2923"
    },
    { 
        id: 38,
        xAxis: "AR", 
        name: "Arkansas",
        yAxis: "47.1",
        number: "2755"
    },
    { 
        id: 39,
        xAxis: "TN", 
        name: "Tennessee",
        yAxis: "34.9",
        number: "4322"
    },
    { 
        id: 40,
        xAxis: "NC", 
        name: "North Carolina",
        yAxis: "41.3",
        number: "5148"
    },
    { 
        id: 41,
        xAxis: "SC", 
        name: "South Carolina",
        yAxis: "15.1",
        number: "862"
    },
    { 
        id: 42,
        xAxis: "DC", 
        name: "District of Columbia",
        yAxis: "13.3",
        number: "89"
    },
    { 
        id: 43,
        xAxis: "AZ", 
        name: "Arizona",
        yAxis: "30.9",
        number: "5474"
    },
    { 
        id: 44,
        xAxis: "OK", 
        name: "Oklahoma",
        yAxis: "48.4",
        number: "4073"
    },
    { 
        id: 45,
        xAxis: "LA", 
        name: "Louisiana",
        yAxis: "1.8",
        number: "81"
    },
    { 
        id: 46,
        xAxis: "MS", 
        name: "Mississippi",
        yAxis: "45.2",
        number: "1981"
    },
    { 
        id: 47,
        xAxis: "AL", 
        name: "Alabama",
        yAxis: "43.3",
        number: "3142"
    },
    { 
        id: 48,
        xAxis: "GA", 
        name: "Georgia",
        yAxis: "40.5",
        number: "5192"
    },
    { 
        id: 49,
        xAxis: "HI", 
        name: "Hawaii",
        yAxis: "32.2",
        number: "623"
    },
    { 
        id: 50,
        xAxis: "TX", 
        name: "Texas",
        yAxis: "62.6",
        number: "21959"
    },
    { 
        id: 51,
        xAxis: "FL", 
        name: "Florida",
        yAxis: "47.9",
        number: "13593"
    },
    { 
        id: 52,
        xAxis: "PR", 
        name: "Puerto Rico",
        yAxis: "15.5",
        number: "360"
    },
    ];
    export default StatisticsData3Age1Older;